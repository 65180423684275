import React from "react"

import Layout from "@src/components/Layout"
import OG from "@src/icons/og/og.png"

import Splash from "./sections/Splash"
import CTA from "./sections/CTA"
import UseCaseVideos from "./sections/UseCaseVideos"
import ClientStrip from "./sections/ClientStrip"
import BigText from "./sections/BigText"
import HowItWorks from "./sections/HowItWorks"
import Separator from "./sections/Separator"
import CaseStudies from "./sections/CaseStudies"
import Stats from "./sections/Stats"
import BigTextAlternate from "./sections/BigTextAlternate"
import Features from "./sections/Features"
import Pricing from "./sections/Pricing"
import LatestArticles from "./sections/LatestArticles"
import * as styles from "./Home.module.scss"

console.log(process.env.NODE_ENV)
const IndexPage = ({ location }) => {
  return (
    <Layout
      title="Canonic | Build full-stack apps using low-code"
      description="The simplest way for teams & businesses to build fullstack applications and workflows."
      url="https://canonic.dev"
      location={location}
      // className={styles.page}
      ogImage={
        process.env.NODE_ENV === "development" ? OG : `https://canonic.dev${OG}`
      }
    >
      <div className={styles.page}>
        <Splash />
        <UseCaseVideos />
        <ClientStrip className="client-strip" />
        <BigText />
        <HowItWorks />
        <Separator />
        <CaseStudies />
        <Stats />
        <BigTextAlternate />
        <Features />
        <Pricing />
        <LatestArticles />
        <CTA />
        {/*<CTA /> */}
      </div>
    </Layout>
  )
}

export default IndexPage

import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import * as styles from "./LatestArticles.module.scss"
import useAppearIn from "../../utils/useAppearIn"

export default function LatestArticles({}) {
  const { latestBlogPosts } = useStaticQuery(graphql`
    query {
      latestBlogPosts {
        blogPosts {
          id
          excerpt
          feature_image
          title
          slug
        }
      }
    }
  `)

  const posts = latestBlogPosts?.blogPosts

  useAppearIn(
    styles.section,
    tl =>
      tl
        .fromTo(
          `.${styles.section} h2`,
          { opacity: 0 },
          { opacity: 1, duration: 3 },
          0
        )
        .fromTo(
          `.${styles.article}`,
          { translateY: 100, opacity: 0 },
          { translateY: 0, opacity: 1, stagger: 0.7 },
          0.7
        ),
    { end: "30%" }
  )
  return (
    <section className={styles.section}>
      <h2>The latest from our blog</h2>
      <div className={styles.articles}>
        {posts.map(post => (
          <Article
            key={post.id}
            id={post.id}
            title={post.title}
            subtitle={post.excerpt}
            image={post.feature_image}
            slug={post.slug}
          />
        ))}
      </div>
    </section>
  )
}

function Article({ title, subtitle, image, slug }) {
  return (
    <a href={`https://canonic.dev/blog/${slug}`} target="_blank">
      <article className={styles.article}>
        <figure className={styles.articleImage}>
          <img src={image} alt="Case Study Image" />
        </figure>
        <div className={styles.articleText}>
          <h3>{title}</h3>
          <p>{subtitle.slice(0, 140)}...</p>
        </div>
      </article>
    </a>
  )
}

import React from "react"
import { ReactComponent as CurvedLine } from "@src/icons/v4/curved-line.svg"
import * as styles from "./BigTextAlternate.module.scss"
import useAppearIn from "../../utils/useAppearIn"

export default function BigTextAlternate({}) {
  useAppearIn(
    styles.bigText,
    tl =>
      tl
        .fromTo(
          `.${styles.bigText} span`,
          { translateY: 50, opacity: 0 },
          { translateY: 0, opacity: 1, stagger: 0.25 },
          0
        )
        .fromTo(
          `.${styles.line}`,
          { translateY: 0, opacity: 0 },
          { translateY: 0, opacity: 1, stagger: 0.15 },
          0.2
        ),
    { end: "20%" }
  )
  return (
    <section className={styles.bigText}>
      <div className={styles.line}>
        <CurvedLine />
      </div>
      <h2 className={styles.title}>
        <span>Everything you need</span>
        <span>
          <strong>on 1 platform</strong>
        </span>
        <span>without lock-in</span>
      </h2>
      <div className={styles.line}>
        <CurvedLine />
      </div>
    </section>
  )
}

import React from "react"
import { ReactComponent as Illustration } from "@src/icons/v4/integrations.svg"
import { ReactComponent as Illustration2 } from "@src/icons/v4/workflows.svg"
import { ReactComponent as Illustration3 } from "@src/icons/v4/frontend.svg"
import * as styles from "./HowItWorks.module.scss"
import { gsap, ScrollTrigger, Draggable, MotionPathPlugin } from "gsap/all"
import { Power4 } from "gsap"

gsap.registerPlugin(ScrollTrigger)
export default function HowItWorks({}) {
  return (
    // <section ref={sectionRef} className={styles.section}>
    <section className={styles.section}>
      <Step1 />
      <Step2 />
      <Step3 />
    </section>
  )
}

function Step1() {
  React.useEffect(() => {
    const allNodes = document.querySelectorAll(".step-1 [id^=alt-node]")
    const mainNode = document.querySelectorAll(".step-1 [id^=main-node]")
    const overlay = document.querySelectorAll(".step-1 [id^=background-inner]")

    const tl = gsap
      .timeline({
        scrollTrigger: {
          scrub: true,
          pin: true,
          end: "+=2000",
          trigger: ".step-1",
          start: "50% 50%",
        },
      })
      .fromTo(`.step-1 .${styles.stepText}`, { opacity: 0 }, { opacity: 1 }, 0)
      .fromTo(
        `.step-1 .${styles.stepIllustration}`,
        { opacity: 0 },
        { opacity: 1 },
        0.1
      )
      .fromTo(
        `.step-1 .${styles.line}`,
        { height: 0 },
        { height: 1500, duration: 1 },
        2.5
      )
      .fromTo(
        [mainNode, ...allNodes],
        { opacity: 0, translateX: 10 },
        { opacity: 1, translateX: 0, stagger: 0.1 },
        0.15
      )
      .fromTo(
        overlay,
        { opacity: 0, scale: 0.9 },
        { opacity: 1, scale: 1.1, ease: Power4.easeOut, duration: 1 },
        "<+1"
      )
      .to(allNodes, { opacity: 0, stagger: 0.1 }, 2.5)
      .to(`.step-1 .${styles.stepText}`, { opacity: 0, duration: 0.5 }, 3)
      .progress(1)
      .progress(0)

    return () => {
      tl.kill()
    }
  }, [])

  return (
    <article className={`${styles.step} step-1`}>
      <div className={styles.line} />
      <div className={styles.stepText}>
        <h3 className={styles.stepTitle}>
          <strong>Connect</strong> your data & services
        </h3>
        <p className={styles.stepDescription}>
          We support all the most popular integrations and databases.
        </p>
      </div>
      <figure className={styles.stepIllustration}>
        <Illustration className={styles.illustration1} />
      </figure>
    </article>
  )
}

function Step2() {
  React.useEffect(() => {
    const get = selector => [...document.querySelectorAll(selector)].reverse()
    const nodeSelector = ".step-2 [id^=nodes] > g"
    const allNodes = get(nodeSelector)
    const allNodeRects = get(`${nodeSelector} > [id^=Rectangle]`)
    const overlay = document.querySelectorAll(".step-2 [id^=background-inner]")

    const tl = gsap
      .timeline({
        scrollTrigger: {
          scrub: true,
          pin: true,
          end: "+=2000",
          trigger: ".step-2",
          start: "50% 50%",
        },
      })
      .fromTo(`.step-2 .${styles.stepText}`, { opacity: 0 }, { opacity: 1 }, 0)
      .fromTo(
        `.step-2 .${styles.stepIllustration}`,
        { opacity: 0 },
        { opacity: 1 },
        0.1
      )
      .fromTo(
        `.step-2 .${styles.line}`,
        { height: 0 },
        { height: 1500, duration: 1 },
        2.5
      )
      .fromTo(
        allNodes,
        { opacity: 0, translateY: 10 },
        { opacity: 1, translateY: 0, stagger: 0.15 },
        0.15
      )
      .to(allNodeRects, { stroke: "#0F0", stagger: 0.5 }, 1)
      .fromTo(
        overlay,
        { opacity: 0, scale: 0.9 },
        { opacity: 1, scale: 1.1, ease: Power4.easeOut, duration: 1 },
        "<+1"
      )
      .to(`.step-2 .${styles.stepText}`, { opacity: 0, duration: 0.5 }, 3)
      .progress(1)
      .progress(0)

    return () => {
      tl.kill()
    }
  }, [])

  return (
    <article className={`${styles.step} step-2`}>
      <div className={styles.line} />
      <div className={styles.stepText}>
        <h3 className={styles.stepTitle}>
          <strong>Add Logic</strong> using workflows
        </h3>
        <p className={styles.stepDescription}>
          Fuse all your data and services together with logic.
        </p>
      </div>
      <figure className={styles.stepIllustration}>
        <Illustration2 className={styles.illustration2} />
      </figure>
    </article>
  )
}

function Step3() {
  React.useEffect(() => {
    const get = selector => [...document.querySelectorAll(selector)].reverse()
    const nodeSelector = ".step-3 [id^=node-]"
    const allNodes = get(nodeSelector)

    const tl = gsap
      .timeline({
        scrollTrigger: {
          scrub: true,
          pin: true,
          end: "+=2000",
          trigger: ".step-3",
          start: "50% 50%",
        },
      })
      .fromTo(`.step-3 .${styles.stepText}`, { opacity: 0 }, { opacity: 1 }, 0)
      .fromTo(
        `.step-3 .${styles.stepIllustration}`,
        { opacity: 0 },
        { opacity: 1 },
        0.1
      )
      .fromTo(
        allNodes,
        { opacity: 0, translateY: 20 },
        { opacity: 1, translateY: 0, stagger: 0.15 },
        0.15
      )
      .progress(1)
      .progress(0)

    return () => {
      tl.kill()
    }
  }, [])

  return (
    <article className={`${styles.step} step-3`}>
      <div className={styles.stepText}>
        <h3 className={styles.stepTitle}>
          <strong>Snap UI</strong> components together
        </h3>
        <p className={styles.stepDescription}>
          Bring everything together in a responsive interface.
        </p>
      </div>
      <figure className={styles.stepIllustration}>
        <Illustration3 className={styles.illustration3} />
      </figure>
    </article>
  )
}

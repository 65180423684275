import React from "react"
import gsap from "gsap"
import * as styles from "./UseCaseVideos.module.scss"
import DashboardVideo from "@src/videos/dashboard.mp4"
import ReportingVideo from "@src/videos/bug-reporting.mp4"
import CrmVideo from "@src/videos/crm.mp4"

const VIDEOS = [DashboardVideo, ReportingVideo, CrmVideo]

console.log(VIDEOS)
export default function UseCaseVideos({}) {
  const [selected, setSelected] = React.useState(0)
  const [cards, setCards] = React.useState(
    new Array(3).fill(0).map((_, i) => i)
  )
  const style = cards.map((_, i) => {
    // when selected is 0, opacity is 1 for i = 0, 1, 2, 3
    // when selected is 1 opacity is 1 for i = 0, 1, 2
    // when selected is 2 opacity is 1 for i = 0, 1
    // when selected is 3 opacity is 1 for i = 0

    return {
      opacity:
        selected === 0
          ? 1
          : selected === 1
          ? i < 2
            ? 1
            : 0
          : selected === 2
          ? i < 1
            ? 1
            : 0
          : selected === 3
          ? i < 0
            ? 1
            : 0
          : 0,
      transform: `scale(${1.4 - (i + selected) / 7}) translate3d(${
        1 || -300 + i * 100 + selected * 100
      }px, ${-90 + i * 50 + selected * 50}px, ${
        -300 + i * 100 + selected * 100
      }px)`,
    }
  })

  React.useEffect(() => {
    const timeline = gsap
      .timeline({
        scrollTrigger: {
          scrub: true,
          end: "25%",
          start: "0% 100%",
          trigger: `.${styles.container}`,
        },
      })
      .fromTo(
        `.${styles.section}`,
        { translateY: -200, scale: 0.9 },
        { scale: 1, translateY: 0 },
        0
      )

    return () => {
      timeline.kill()
    }
  })

  return (
    <div className={styles.container}>
      <section className={styles.section}>
        <div className={styles.tabs}>
          {/* <button
          onClick={() => setSelected(0)}
          className={`${styles.tab} ${selected === 0 ? styles.active : ""}`}
        >
          Workflows
        </button> */}
          <button
            onClick={() => setSelected(0)}
            className={`${styles.tab} ${selected === 0 ? styles.active : ""}`}
          >
            Dashboards
          </button>
          <button
            onClick={() => setSelected(1)}
            className={`${styles.tab} ${selected === 1 ? styles.active : ""}`}
          >
            Tools for Teams
          </button>
          <button
            onClick={() => setSelected(2)}
            className={`${styles.tab} ${selected === 2 ? styles.active : ""}`}
          >
            User Facing Apps
          </button>
          <div className={styles.underline} />
        </div>
        <div className={styles.previews}>
          {cards.map((c, i) => (
            <>
              <div key={c} style={style[i]} className={styles.preview}>
                <div
                  style={{
                    transition: "all 0.3s ease",
                    opacity: cards.length - selected - 1 === i ? 1 : 0,
                  }}
                >
                  <video src={VIDEOS[selected]} autoPlay loop muted />
                </div>
              </div>
            </>
          ))}
        </div>
      </section>
    </div>
  )
}

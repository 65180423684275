import React from "react"
import Link from "gatsby-plugin-transition-link"
import clsx from "clsx"
import getAppUrl from "@src/utils/getAppUrl"
import * as styles from "./CTA.module.scss"
import { trackV2 } from "../../../../../utils/track"

export default function CTA({ location }) {
  const appUrl = getAppUrl.hook(location)
  return (
    <section className={styles.section}>
      <h1 className={styles.title}>
        Build full-stack applications without code
      </h1>
      <p className={styles.subtitle}>
        Build and deploy production-grade applications without code.
        <br />
        Get started in minutes and master it in hours.
      </p>
      <div className={styles.ctas}>
        <a
          onClick={() =>
            trackV2("Clicked on a CTA", {
              category: "Click",
              label: "Start for free",
            })
          }
          className={styles.cta}
          href={appUrl}
          target="_blank"
        >
          Get started
        </a>
        <Link
          className={clsx(styles.cta, styles.secondary)}
          to={"get-a-demo"}
          entry={{ length: 0.3 }}
          exit={{ length: 0.3 }}
        >
          Request a demo
        </Link>
      </div>
    </section>
  )
}

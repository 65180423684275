import React from "react"
import clsx from "clsx"
import { ReactComponent as SelfHosted } from "@src/icons/v4/self-hosted.svg"
import { ReactComponent as CodeExport } from "@src/icons/v4/code-export.svg"
import { ReactComponent as FullStack } from "@src/icons/v4/full-stack.svg"
import * as styles from "./Features.module.scss"
import useAppearIn from "../../utils/useAppearIn"
import gsap from "gsap"

export default function Features({}) {
  useAppearIn(
    styles.features,
    tl =>
      tl.fromTo(
        `.${styles.card}`,
        { translateY: 100, opacity: 0 },
        { translateY: 0, opacity: 1, stagger: 0.1 },
        0
      ),
    { end: "30%" }
  )

  React.useEffect(() => {
    const paths = document.querySelectorAll("[id^=ring-]")
    const anims = [...paths].map((path, i) => {
      const circle = document.querySelector(`[id=dot-${i + 1}]`)
      const circleDot = document.querySelector(`[id=dot-${i + 1}] circle`)
      // Create an object that gsap can animate
      const val = { distance: 0 }
      // Create a tween
      return gsap.to(val, {
        repeat: -1,
        duration: i + 2,
        // Animate from distance 0 to the total distance
        distance: path.getTotalLength(),
        ease: "none",
        // Function call on each frame of the animation
        onUpdate: () => {
          // Query a point at the new distance value
          const point = path.getPointAtLength(val.distance)
          // Update the circle coordinates
          circleDot.setAttribute("cx", "100%")
          circleDot.setAttribute("cy", "100%")
          circle.setAttribute(
            "transform",
            `translate(-${point.x}, -${point.y})`
          )
        },
      })
    })

    return () => {
      anims.forEach(anim => anim.kill())
    }
  }, [])
  return (
    <section className={styles.features}>
      <div className={styles.row}>
        <div className={styles.column}>
          <Card
            hasImage
            title="Self Hosted"
            description="Self host Canonic on your own infrastructure for complete control."
            image={<SelfHosted className={styles.selfHosted} />}
          />
        </div>
        <div className={styles.column}>
          <Card
            small
            title="Computed Fields"
            description="Create virtual fields powered by code on Canonic tables."
          />
          <Card
            small
            title="CMS"
            description="A Content Management System to manage your data."
          />
        </div>
        <div className={styles.column}>
          <Card
            hasImage
            title="Code Export"
            description="Eject out of Canonic anytime with human readable code export."
            image={<CodeExport className={styles.codeExport} />}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <Card
            small
            title="Infinite Canvas"
            description="Build responsive apps with powerful layouting features."
          />
          <Card
            small
            title="APIs"
            description="Trigger any workflow as a REST endpoint."
            // hasImage
          />
        </div>
        <div className={styles.column}>
          <Card
            hasImage
            title="Full Stack"
            description="All batteries included. Frontend, backend, workflows, & integrations."
            image={<FullStack className={styles.fullStack} />}
          />
        </div>
        <div className={styles.column}>
          <Card
            small
            title="Design System"
            description="35+ components including the ability to create your own."
            // hasImage
          />
          <Card
            small
            title="Compositions"
            description="Drag & drop Kanban Boards, Gantt charts, Datagrids, & more."
          />
        </div>
      </div>
    </section>
  )
}

function Card({ small, hasImage, title = "Theming", image, description }) {
  return (
    <article className={clsx(styles.card, { [styles.small]: small })}>
      {hasImage && <figure className={styles.cardImage}>{image}</figure>}
      <div className={styles.cardText}>
        <h3 className={styles.cardTitle}>{title}</h3>
        <p className={styles.cardDescription}>{description}</p>
      </div>
    </article>
  )
}

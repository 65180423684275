import React from "react"
import SeparatorImage from "@src/icons/v4/separator.png"
import * as styles from "./Separator.module.scss"
import { gsap, ScrollTrigger } from "gsap/all"
gsap.registerPlugin(ScrollTrigger)

export default function Separator({}) {
  React.useEffect(() => {
    const timeline = gsap
      .timeline({
        scrollTrigger: {
          scrub: true,
          // pin: true,
          // end: "+=800",
          trigger: `.${styles.separator}`,
          start: "0% 100%",
        },
      })
      .fromTo(
        `.${styles.separator} img`,
        { translateX: -20 },
        { translateX: 20 },
        0
      )

    return () => {
      timeline.kill()
    }
  })
  return (
    <div className={styles.separator}>
      <img src={SeparatorImage} />
      <img src={SeparatorImage} />
      <img src={SeparatorImage} />
      <img src={SeparatorImage} />
      <img src={SeparatorImage} />
      <img src={SeparatorImage} />
      <img src={SeparatorImage} />
      <img src={SeparatorImage} />
      <img src={SeparatorImage} />
      <img src={SeparatorImage} />
      <img src={SeparatorImage} />
      <img src={SeparatorImage} />
    </div>
  )
}

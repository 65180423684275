// extracted by mini-css-extract-plugin
export var background = "HowItWorks-module--background--3MUf3";
export var illustration1 = "HowItWorks-module--illustration1--2YqGw";
export var illustration2 = "HowItWorks-module--illustration2--ZtBWr";
export var illustration3 = "HowItWorks-module--illustration3--3iFMp";
export var line = "HowItWorks-module--line--nsobQ";
export var section = "HowItWorks-module--section--3MaAY";
export var step = "HowItWorks-module--step--1JkwM";
export var stepDescription = "HowItWorks-module--stepDescription--2Di0G";
export var stepIllustration = "HowItWorks-module--stepIllustration--23zpr";
export var stepText = "HowItWorks-module--stepText--3JXy5";
export var stepTitle = "HowItWorks-module--stepTitle--2B4dH";
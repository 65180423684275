import React from "react"
import * as styles from "./BigText.module.scss"
import useAppearIn from "../../utils/useAppearIn"

export default function BigText({}) {
  useAppearIn(
    styles.bigText,
    tl =>
      tl
        .fromTo(
          `.${styles.title} strong`,
          { translateY: 100, opacity: 0 },
          { translateY: 0, opacity: 1, stagger: 0.1 },
          0.2
        )
        .fromTo(
          `.${styles.rings}`,
          { translateY: 100, scale: 0, opacity: 0 },
          { translateY: -100, scale: 1, opacity: 1, stagger: 0.1 },
          0
        )
        .fromTo(
          `.${styles.title}`,
          { translateY: 0, opacity: 0 },
          { translateY: 0, opacity: 1, stagger: 0.1 },
          0
        ),
    { end: "50%" }
  )
  return (
    <section className={styles.bigText}>
      <h2 className={styles.title}>
        <strong>Drastically reduce time & complexity</strong>.{" "}
        <strong>Write code</strong> anywhere. <strong>Deploy</strong> in
        seconds.
      </h2>
      <div className={styles.rings}>
        {new Array(5).fill(0).map((_, i) => (
          <div
            key={i}
            className={styles.ring}
            style={{ animationDelay: `${i * -2}s` }}
          />
        ))}
      </div>
    </section>
  )
}

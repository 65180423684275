import React from "react"
import * as styles from "./CaseStudies.module.scss"
import useAppearIn from "../../utils/useAppearIn"

export default function CaseStudies({}) {
  useAppearIn(
    styles.section,
    tl =>
      tl
        .fromTo(
          `.${styles.caseStudy}`,
          { translateY: 150, opacity: 0 },
          { translateY: 0, opacity: 1, stagger: 0.1 },
          0.1
        )
        .fromTo(
          `.${styles.section} h2`,
          { translateY: 150, opacity: 0 },
          { translateY: 0, opacity: 1 },
          0
        ),
    {
      end: "30%",
      start: "0% 100%",
    }
  )

  return (
    <section className={styles.section}>
      <h2>Case Studies</h2>
      <div className={styles.caseStudies}>
        <CaseStudy
          i={0}
          title="Fortune Marketing transforms distributor operations using Canonic"
          src="https://canonic.dev/blog/og/64c8aa1a87fe5700015c8f7e_fortune.jpg"
        />
        <CaseStudy
          title="Tamarind Chutney saves 72 hours every month using Canonic to automate Shopify."
          src="https://canonic.dev/blog/og/64992a6c667843000103702f_tamarind-chutney.jpg"
          i={1}
        />
        {/* <CaseStudy i={2} /> */}
        {/* <CaseStudy i={3} /> */}
      </div>
    </section>
  )
}

function CaseStudy({ i, title, src }) {
  return (
    <article className={styles.caseStudy}>
      <figure className={styles.caseStudyImage}>
        <img src={src} alt={`${title}-image`} />
      </figure>
      <h3>{title}</h3>
    </article>
  )
}

import React from "react"
import * as styles from "./Stats.module.scss"
import useAppearIn from "../../utils/useAppearIn"

export default function Stats({}) {
  useAppearIn(
    styles.stats,
    tl =>
      tl.fromTo(
        `.${styles.stat}`,
        { translateY: 10, opacity: 0 },
        { translateY: 0, opacity: 1, stagger: 0.1 },
        0
      ),

    { end: "10%" }
  )

  return (
    <section className={styles.stats}>
      <Stat value={1000} postfix="+" title="Apps Created" />
      <Stat value={10} postfix="K+" title="Creators" />
      <Stat value={1} postfix="M+" title="API Calls" />
    </section>
  )
}

function Stat({ value, title, postfix }) {
  const spanRef = React.useRef(null)
  useAppearIn(
    styles.stat,
    tl =>
      tl.fromTo(
        spanRef.current,
        {
          opacity: 0,
          innerText: 0,
        },
        {
          opacity: 1,
          innerText: value,
          duration: 0.1,
          snap: {
            innerText: 1,
          },
        },
        0
      ),
    { end: "+=500" }
  )

  return (
    <article className={styles.stat}>
      <h3 className={styles.value}>
        <span ref={spanRef}>{value}</span>
        {postfix}
      </h3>
      <p className={styles.title}>{title}</p>
    </article>
  )
}

import React from "react"
import * as styles from "./Pricing.module.scss"
import { gsap, ScrollTrigger } from "gsap/all"
import { Power2, Power3 } from "gsap"
gsap.registerPlugin(ScrollTrigger)

export default function Pricing({}) {
  const sectionRef = React.useRef(null)
  React.useEffect(() => {
    if (!sectionRef.current) return
    const timeline = gsap
      .timeline({
        scrollTrigger: {
          // once: true,
          scrub: true,
          // pin: true,
          end: "+=800",
          trigger: sectionRef.current,
          start: "0% 100%",
        },
      })
      .fromTo(
        `.${styles.pricingCard}`,
        { translateY: 0, scale: 1.3 },
        { translateY: 0, scale: 1.3, stagger: 0.1 },
        0
      )
      .fromTo(
        `.${styles.pricingCard}:nth-child(2n + 1)`,
        { rotate: -7 },
        { rotate: -7 },
        0
      )
      .fromTo(
        `.${styles.pricingCard}:nth-child(2n)`,
        { rotate: 7 },
        { rotate: 7 },
        0
      )
      .to(
        `.${styles.pricingCard}`,
        {
          translateY: 0,
          rotate: 0,
          scale: 1,
          stagger: 0.1,
          ease: Power2.easeIn,
        },
        ">=+0"
      )
      .fromTo(
        `.${styles.sectionTitle}`,
        { translateY: 130, opacity: 0 },
        { translateY: 0, opacity: 1 },
        ">=-0.5"
      )

    return () => {
      timeline.kill()
    }
  })
  return (
    <section ref={sectionRef} className={styles.section}>
      <h2 id="okay" className={styles.sectionTitle}>
        Pricing
      </h2>
      <div className={styles.pricingCards}>
        <PricingCard
          title="Essential"
          pricing="Free"
          features={["Unlimited Projects", "Unlimited Users", "1GB Storage"]}
        />
        <PricingCard
          title="Solo"
          pricing="$28/mo"
          features={[
            "<strong>Everything in Essential + </strong>",
            "20GB Storage",
            "1 Collaborators",
            "7 days of Revision History",
            "Unlimited Custom Domain",
            "Unlimited Environments",
            "Code Export",
          ]}
        />
        <PricingCard
          title="Pro"
          pricing="$120/mo"
          features={[
            "<strong>Everything in Essential + </strong>",
            "100GB Storage",
            "5 Collaborators",
            "60 days of Revision History",
            "Unlimited Custom Domain",
            "Unlimited Environments",
            "Code Export",
          ]}
        />

        <PricingCard
          title="Enterprise"
          pricing="Custom"
          features={[
            "<strong>Everything in Pro +</strong>",
            "Unlimited Collaborators",
            "100GB Storage",
            "Unlimited Revision History",
            "Unlimited Custom Domain",
            "Unlimited Environments",
            "Audit Logs",
            "Dedicated Account Manager",
          ]}
        />
      </div>
    </section>
  )
}

function PricingCard({ title, pricing, features }) {
  return (
    <article className={styles.pricingCard}>
      <h3 className={styles.title}>{title}</h3>
      <h4 className={styles.price}>{pricing}</h4>
      <ul className={styles.limits}>
        {features.map(f => (
          <li
            key={f}
            className={styles.limit}
            dangerouslySetInnerHTML={{ __html: f }}
          />
        ))}
      </ul>
    </article>
  )
}

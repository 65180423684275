// extracted by mini-css-extract-plugin
export var blink = "Features-module--blink--eUiOS";
export var card = "Features-module--card--1j_yb";
export var codeExport = "Features-module--codeExport--2OUn4";
export var column = "Features-module--column--1F1kT";
export var features = "Features-module--features--E1t93";
export var fullStack = "Features-module--fullStack--2SGQF";
export var row = "Features-module--row--2FWjG";
export var selfHosted = "Features-module--selfHosted--3E5Xm";
export var small = "Features-module--small--1ByYt";
export var strokeAnimate = "Features-module--stroke-animate--1eLw-";